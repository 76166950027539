import React, { FC } from 'react'
import { PostGrid } from '../components'
import { LayoutPage } from '../layouts/LayoutPage';

const Works: FC = () => (
    <LayoutPage>
        <PostGrid />
    </LayoutPage>
);

export default Works;
